import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
const RoleMenuHelper = defineComponent({
    name: "RoleMenu",
    title: "角色菜单管理",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        let dataObj=reactive({
            treeRef:null,
            compParams: {
                modelPath:'/roleMenu'
            },
            defaultProps: {//设置树的显示属性和孩子属性是json数据中的哪个字段
                children: "children",
                label: "title"
            },
            topMenuTree: {}, //顶层tab和tab包含的树节点信息
            activeMenuId: "", //当前点击的活动menu节点
            treeData: [], //当前被选择的tab要显示的树

            menuSelPermissionIterms: {}, //每个menu节点的功能权限，里面是一个个的menuId作为key，一个初始化的空数组作为value，动态添加的属性
            menuSelKeys: [], //选中打勾的菜单项
            roleId: '',
            enginInst:{},
            form: {}
        })
        const addOrLoad=async(enginInst)=>{
            let res = await utils.$$api.loadRoleMenu({ roleId: enginInst.engineParams.id,modelType:'card'});
            enginInst.buttons=res.buttons;
            let allMenus = JSON.parse(res.allMenus);
            buildTopMenuTree(allMenus);//构建菜单tab和tab里面的顶级树节点
            dataObj.menuSelPermissionIterms = res.mapMenuPermissions;
            dataObj.menuSelKeys = res.selMenus;
            dataObj.treeRef.setCheckedKeys(res.selMenus);//根据该用户所拥有的末级菜单选择当前正在显示的树中对应树节点
            dataObj.roleId = enginInst.engineParams.id;//dialogInst实例已经传给当前组件了，可以通过dialogInst实例得到从列表传入给它的id，这个id就是角色id
            dataObj.enginInst=enginInst;//保存dialogInst实例到当前组件，方便使用
        }

        const buildTopMenuTree=(allMenus)=>{
            let topMenuTree = {
                topMenus: [], //头部显示的顶层菜单，相当于tab
                trees: [] //每点击一个顶部菜单，下方要显示的树结构，先把所有的树结构放到一个数组里面，结构是：顶层{顶层菜单ID，这个顶层菜单的所有子菜单（包括顶层菜单）}
            };
            for (let i = 0; i < allMenus.length; i++) {//注意：只会循环顶层菜单个数，因为没有去递归子菜单
                let menu = {};
                let menuIterm = allMenus[i];

                //不需要JSON化，在页面用的时候再JSON.parse(data.menuItermPermission)
                menuIterm.menuItermPermission =allMenus[i].menuItermPermission;
                menu.menuId = menuIterm.id;
                menu.title = menuIterm.title;
                topMenuTree.topMenus.push(menu);//由于只循环了顶层菜单，没有递归子菜单，所以topMenuTree下的topMenus数组只存放了顶层菜单信息

                let tree = {};
                tree.parentId = menuIterm.id;
                tree.treeData = menuIterm;//把顶层菜单赋值给treeData,于是treeData就包含有这个顶层菜单以及它的孩子节点
                topMenuTree.trees.push(tree);//把构造好的树节点放进topMenuTree下trees数组中，有多少个顶层菜单就会放进去多少个树节点
            }
            dataObj.topMenuTree = topMenuTree;
            //初始化的时候，默认第一个菜单是激活项
            dataObj.activeMenuId = dataObj.topMenuTree.topMenus[0].menuId;
            dataObj.treeData = [dataObj.topMenuTree.trees[0].treeData];
        }
        const isActive=(menuId)=>{
            return menuId == dataObj.activeMenuId;
        }
        //切换顶部tabs
        const tagClick=(menuId)=>{
            //如果当前点击的menuId就是正在显示的menu，那么直接返回
            if (menuId == dataObj.activeMenuId) return;

            //1.处理当前要离开的tree，看哪些节点选中了，哪些又被取消选中了
            let treeNode = dataObj.treeRef.data;//当前树的所有节点
            let checkedKeys = dataObj.treeRef.getCheckedKeys();//当前树已经选中的节点
            addSelKeys();
            //2.把本次取消选中但是还在选中列表中的key去除掉
            removeUnSelKeys(treeNode, checkedKeys);

            dataObj.activeMenuId = menuId;//更改了tab项，则需要改变tab的激活项id，用于高亮显示
            let trees = dataObj.topMenuTree.trees;
            for (let i = 0; i < trees.length; i++) {//寻找当前激活项应该显示的树节点的数据源
                if (menuId == trees[i].parentId) {
                    dataObj.treeData = [trees[i].treeData];
                    break;
                }
            }
            //3.当把新的tree显示出来之后，需要把已经选中的节点给选中出来
            dataObj.treeRef.setCheckedKeys(dataObj.menuSelKeys);
        }
        //往menuSelKeys添加本次选中的树节点，添加之前要看是否menuSelKeys中已经存在了
        const addSelKeys=()=>{
            //处理当前要离开的tree，看哪些节点选中了，哪些又被取消选中了
            let checkedKeys = dataObj.treeRef.getCheckedKeys();
            //1.本次选中的节点是否已经添加进入已选中节点数组中
            for (let i = 0; i < checkedKeys.length; i++) {
                if (dataObj.menuSelKeys.indexOf(checkedKeys[i]) == -1) {
                    dataObj.menuSelKeys.push(checkedKeys[i]);
                }
            }
        }
        //从menuSelKeys数组中移除掉本次取消选择的树节点
        //nodes为当前树的所有节点，checkedKeys为当前树选中的节点
        const removeUnSelKeys=(nodes, checkedKeys)=>{
            for (let i = 0; i < nodes.length; i++) {//循环当前树所有节点
                let node = nodes[i];
                //如果当前节点没有被选中，但是该节点之前被添加进menuSelKeys数组中了，那么就移除它
                if (checkedKeys.indexOf(node.id) == -1 && dataObj.menuSelKeys.indexOf(node.id) > -1) {
                    dataObj.menuSelKeys.splice(dataObj.menuSelKeys.indexOf(node.id), 1);
                }
                //如果该节点还有子节点，则递归所有的子节点
                if (node.children) {
                    removeUnSelKeys(node.children, checkedKeys);
                }
            }
        }

        const getSaveData=()=>{
            let treeNode = dataObj.treeRef.data;
            let checkedKeys = dataObj.treeRef.getCheckedKeys();
            //保存之前，需要把当前已经选择和去除的菜单项在menuSelKeys数组中处理一下
            addSelKeys();
            removeUnSelKeys(treeNode, checkedKeys);
            //返回要保存的数据信息
            return {
                menuSelKeys: dataObj.menuSelKeys,
                menuSelPermissionIterms: dataObj.menuSelPermissionIterms
            };
        }
        //-----------------卡片相关
        //角色菜单保存
        const saveRoleMenuHandler=async()=>{
            const loading = proxy.$loading({lock: true, text: '正在保存,请稍后......', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
            let menuSelKeys = getSaveData().menuSelKeys;
            let menuSelPermissionIterms = getSaveData().menuSelPermissionIterms;

            //如果一个菜单都没用选择，那么没用必要发送数据库保存了。
            if (menuSelKeys.length == 0) {
                utils.$$tools.info({ message: "没有选择任何菜单"});
                loading.close();
                return;
            }
            //为了少传一些数据给后台
            //1、去除掉勾选了菜单，但是勾选菜单没有任何权限点的项
            //2、去除设置了菜单的权限点，但是没有勾选菜单
            for (let key in menuSelPermissionIterms) {
                if (menuSelPermissionIterms[key].length == 0 ||menuSelKeys.indexOf(key) == -1) {
                    delete menuSelPermissionIterms[key];
                }
            }
            //上面已经处理了menuSelPermissionIterms，下面为什么还要构造整理一下menuSelPermissionIterms呢
            //因为menuSelPermissionIterms的权限点是一个数组，如果直接把menuSelPermissionIterms传入后台，
            //那么就需要在后台把权限点处理一下，这里直接在前台处理，没有交给后台，
            //把menuSelPermissionIterms中的权限点由数组全部转为字符串加逗号连接起来
            let menuPermissions = {};
            for (let key in menuSelPermissionIterms) {
                let perStr = "";
                for (let i = 0; i < menuSelPermissionIterms[key].length; i++) {
                    perStr = perStr + menuSelPermissionIterms[key][i] + ",";
                }
                perStr = perStr.substr(0, perStr.length - 1);
                menuPermissions[key] = perStr;
            }
            let params={roleId:dataObj.roleId,menuPermissions:menuPermissions};
            let res = await utils.$$api.saveRoleMenu(params);
            if(res.result){
                dataObj.enginInst.$parent.$parent.dialogVisible = false;
                utils.$$tools.success({ message: res.msg });
            }
            loading.close();
        }
        //角色菜单重置,重新调用opened方法，里面重新从后台查询并为tab和tree赋值
        const resetRoleMenuHandler=()=>{
            addOrLoad(dataObj.enginInst);
            utils.$$tools.success({ message: "表单重置成功!"});
        }
        //选中全部权限事件
        const selAll=(node, data)=>{
            selAllPermission(data);
        }
        //给当前树节点每个叶子节点赋值上全部权限
        const selAllPermission=(data)=>{
            const id=data.id;
            if(data.children){//有孩子节点
                for(let i=0;i<data.children.length;i++){
                    selAllPermission(data.children[i]);
                }
            }else{//叶子节点
                const menuItermPermission=JSON.parse(data.menuItermPermission);
                let permissionValue=[];
                menuItermPermission.forEach((item,index)=>{
                    permissionValue.push(item.code);
                },permissionValue);
                dataObj.menuSelPermissionIterms[id]=permissionValue;
            }
        }
        //取消全部权限事件
        const cancelAll=(node, data)=>{
            cancelAllPermission(data);
        }
        //给当前树节点每个叶子节点的权限全部清空
        const cancelAllPermission=(data)=>{
            const id=data.id;
            if(data.children){//有孩子节点
                for(let i=0;i<data.children.length;i++){
                    cancelAllPermission(data.children[i]);
                }
            }else{//叶子节点
                dataObj.menuSelPermissionIterms[id]='';
            }
        }
        return{
            ...toRefs(dataObj),addOrLoad,buildTopMenuTree,isActive,tagClick,addSelKeys,removeUnSelKeys,getSaveData,saveRoleMenuHandler,resetRoleMenuHandler,
            selAll,cancelAll
        }
    },
    components: {}
});
export default RoleMenuHelper;